import { gql } from '@apollo/client';

export const ADD_ORDER = gql`
  mutation($orderInput: String!) {
    addOrder(orderInput: $orderInput) {
      id
      userId
      products {
        id
        title
      }
      status
    }
  }
`;

// export const QuotePayload = `
//   {
//     _id
//     uniqueCode
//     amount
//     products {
//       _id
//       productId
//       name
//       detailView
//       thumb
//       mobileIcon
//       price
//       priceValue
//       description
//       quantity
//       total
//       productMeta {
//         _id
//         type
//         itemId
//         itemName
//         itemUniqueCode
//       }
//     }
//     createdAt
//     createdBy
//     createdByUserName
//     modifiedAt
//     modifiedBy
//     modifiedByUserName
//   }
// `;

export const QuotePayload = `
  {
    _id
    uniqueCode
    name
    thumb
    currencyName
    currencySymbol
    currencyId
    unit
    unitSymbol
    template
    templateData
    raws
    products {
      _id
      productId
      name
      detailView
      thumb
      mobileIcon
      price
      priceValue
      description
      quantity
      total
      productMeta {
        _id
        type
        itemId
        itemName
        itemUniqueCode
      }
    }
    createdAt
    createdBy
    createdByUserName
    modifiedAt
    modifiedBy
    modifiedByUserName
  }
`;

export const ADD_QUOTE = gql`
  mutation(
    $userId: String, 
    $name: String 
    $email: String 
    $companyName: String 
    $products: [QuoteProductObject]
  ) {
    addQuote(
      userId: $userId, 
      name: $name
      email: $email
      companyName: $companyName
      products: $products
    ) ${QuotePayload}
  }
`;

export const GET_PAYMENT = gql`
  mutation($paymentInput: String!) {
    charge(paymentInput: $paymentInput) {
      status
    }
  }
`;

export const ENQUIRY_NOW = gql`
  mutation(
    $userId: String 
    $name: String 
    $email: String 
    $companyName: String 
    $products: [QuoteProductObject]
  ) {
    enquiryNow(
      userId: $userId
      name: $name
      email: $email
      companyName: $companyName
      products: $products
    ) ${QuotePayload}
  }
`;