import PubNub from 'pubnub';
import _ from 'lodash';
import moment from 'moment';
import uuid4 from "uuid4";

export class ChatHelper {

  constructor(uuid) {

    this.pubnub = new PubNub({
      publishKey: process.env.publishKey,
      subscribeKey: process.env.subscribeKey,
      ssl: window.location.protocol === 'https:',
      uuid: uuid
    });

    window.pubnub = this.pubnub;
  }

  getUUID = () => {
    return this.pubnub.getUUID();
  };

  subscribeChannels = (channels, withPresence = true) => {
    return new Promise((resolve) => {
      this.pubnub.subscribe({channels: channels, withPresence: withPresence}, function (status) {
        resolve({status});
      });
    });
  };

  unsubscribeChannels = (channels) => {
    return new Promise((resolve) => {
      this.pubnub.unsubscribe({channels: channels}, function (status) {
        resolve({status});
      });
    });
  };

  publishToChannel = (channel, message) => {
    return new Promise((resolve) => {
      this.pubnub.publish({channel, message}, function (status, response) {
        resolve({status, response});
      });
    });
  };

  hereNow = (channels, callback) => {
    this.pubnub.hereNow({
      channels: channels,
      includeUUIDs: true,
      includeState: true,
    }, callback);
  };

  fetchMessage = ({channels, start, count = 100}, callback) => {
    let fetchOptions = {
      channels,
      count: count
    };

    start ? fetchOptions.start = start : null;

    this.pubnub.fetchMessages(fetchOptions, callback);
  };

  getHistory = (channel, callback) => {
    this.pubnub.history({channel}, callback);
  };

  sortMessagesByDate = (messageList, oldSortedMessage = {}) => {
    let dateSorted = oldSortedMessage;

    messageList.forEach(message => {
      let day = moment(new Date(parseInt(message.timetoken) / 10000)).format("YYYYMMDD");
      if (typeof dateSorted[day] === 'undefined') {
        dateSorted[day] = [];
      }

      dateSorted[day].push(message);
    });
    return dateSorted;
  }
}
