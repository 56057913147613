import React from 'react';
import Comment from './comment';

function Comments(props) {

  const {messages} = props;

  return (
    <>
      <h3 className="title">Comments</h3>
      <ul className="product--review-comments list-unstyled">
        {
          messages && messages.length > 0 ? messages.map((message, index) => {
            return <li key={index} className="review--comment">
              <Comment
                key={index}
                index={index}
                entry={message}
                companyId={props.companyId}
                companyName={props.companyName}
                chatHelper={props.chatHelper}
              />
            </li>
          }) : <li>No comments</li>
        }
        {/*<li>*/}
        {/*  <Comment image={`assets/images/blog/comments/1.jpg`} name="Jimmy Pearson" date="November 9, 2018 at 2:19 pm"*/}
        {/*           content="Sed pretium, ligula sollicitudin laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor eu nibh. Nullam mollis. Ut justo. Suspendisse potenti. "/>*/}
        {/*</li>*/}

        {/*<li>*/}
        {/*  <Comment image={`assets/images/blog/comments/3.jpg`} name="Johnathan Castillo"*/}
        {/*           date="November 9, 2018 at 2:19 pm"*/}
        {/*           content="Vestibulum volutpat, lacus a ultrices sagittis, mi neque euismod dui, eu pulvinar nunc sapien ornare nisl. Phasellus pede arcu, dapibus eu, fermentum et, dapibus sed, urna."/>*/}
        {/*</li>*/}
      </ul>
    </>
  );
}

export default Comments;