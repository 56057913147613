import React, {Component} from 'react';
import {ChatHelper} from "../../utils/ChatHelper";
import _ from "lodash";
import Comments from "../Comments/Comments";
import Reply from "./components/Reply";

class CommentWidget extends Component {

  constructor(props) {
    super(props);

    this.state = {
      channel: this.props.channel,
      messages: [],
      chats: {},
      messagesList: {},
      messagesIds: {},
      selectedChatMessages: {}
    };

    this.chatHelper = new ChatHelper(props.uuid);

    this.liveChatListener = {
      message: messageEvent => {
        this.setMessages(messageEvent);
      },
      signal: signalEvent => {
      },
      presence: presenceEvent => {
        if (presenceEvent.action === "join") {
          let {chats} = this.state;
          if (chats[presenceEvent.channel]) {
            let newOccupants = chats[presenceEvent.channel].occupants.filter(it => it.uuid !== presenceEvent.uuid);
            newOccupants.push({uuid: presenceEvent.uuid, channel: presenceEvent.channel, state: presenceEvent.state});
            chats[presenceEvent.channel].occupants = newOccupants;
            chats[presenceEvent.channel].occupancy = presenceEvent.occupancy;
            this.setState({chats});
          }

        } else if (presenceEvent.action === "leave") {
          let {chats} = this.state;
          if (chats[presenceEvent.channel]) {
            chats[presenceEvent.channel].occupants = chats[presenceEvent.channel].occupants.filter(it => it.uuid !== presenceEvent.uuid);
            chats[presenceEvent.channel].occupancy = presenceEvent.occupancy;
            this.setState({chats});
          }

        } else if (presenceEvent.action === "state-change") {
          let {chats} = this.state;
          if (chats[presenceEvent.channel]) {
            let newOccupants = chats[presenceEvent.channel].occupants.filter(it => it.uuid !== presenceEvent.uuid);
            newOccupants.push({uuid: presenceEvent.uuid, channel: presenceEvent.channel, state: presenceEvent.state});
            chats[presenceEvent.channel].occupants = newOccupants;
            chats[presenceEvent.channel].occupancy = presenceEvent.occupancy;
            this.setState({chats});
          }

        } else if (presenceEvent.action === "timeout") {
          let {chats} = this.state;
          if (chats[presenceEvent.channel]) {
            chats[presenceEvent.channel].occupants = chats[presenceEvent.channel].occupants.filter(it => it.uuid !== presenceEvent.uuid);
            chats[presenceEvent.channel].occupancy = presenceEvent.occupancy;
            this.setState({chats});
          }

        }
      },
      status: statusEvent => {
        if (statusEvent.category === "PNConnectedCategory") {
          this.getHereNow();
          this.getChatHistory();
        }
      }
    };

    this.chatHelper.pubnub.removeListener(this.liveChatListener);

  }

  componentDidMount() {
    this.chatHelper.pubnub.addListener(this.liveChatListener);
    this.chatHelper.subscribeChannels([this.state.channel]);
  }

  setMessages = (message) => {
    let {messages = []} = this.state;
    messages.push(message);
    messages = _.sortBy(messages, ["timetoken"]);
    this.setState({messages: messages});

    // if (document.querySelector('#messageList')) {
    //   document.querySelector('#messageList').scrollTop = document.querySelector('#messageList').scrollHeight;
    // }

    let {messagesList, messagesIds} = this.state;

    if (typeof messagesList[`${message.channel}`] === 'undefined') {
      messagesList[`${message.channel}`] = {}
    }
    if (typeof messagesIds[`${message.channel}`] === 'undefined') {
      messagesIds[`${message.channel}`] = []
    }
    // if (typeof this.deleted_ids[`${message.channel}`] === 'undefined') {
    //   this.deleted_ids[`${message.channel}`] = []
    // }

    if (messagesIds[message.channel].indexOf(message.message.message_id) < 0) {
      messagesIds[message.channel].push(message.message.message_id);
      messagesList[message.channel][message.message.message_id] = message;
    } else {
      // if (message.deleted) {
      //   this.deleted_ids[message.channel].push(message.message.message_id);
      //   delete this.message_list[message.channel][message.message.message_id];
      // } else {
      //   this.message_list[message.channel][message.message.message_id] = message;
      // }
    }

    this.setState({messagesList: messagesList, messagesIds: messagesIds}, () => {
      this.updateChat();
    });

  };

  getHereNow = () => {

    let _this = this;

    setTimeout(() => {
      this.chatHelper.hereNow([this.state.channel], function (status, response) {
        if (!status.error) {
          _this.setState({chats: response && response.channels || {}});
        }
      });
    }, 500);

  };

  getChatHistory = () => {
    this.chatHelper.fetchMessage({channels: [this.state.channel]}, (status, results) => {
      if (!status.error) {
        console.log("results = ", results);
        this.updateMessageStore(results);
      }
      let messages = results.channels[this.state.channel] && results.channels[this.state.channel] || [];
      messages = _.sortBy(messages, ["timetoken"]);
      this.setState({messages: messages}, () => {
        // if (document.querySelector('#messageList')) {
        //   document.querySelector('#messageList').scrollTop = document.querySelector('#messageList').scrollHeight;
        // }
      });
    });
  };

  fetchMore = (timetoken, channels) => {
    let oldHeight = document.querySelector('#messageList') && document.querySelector('#messageList').scrollHeight || 0;

    this.chatHelper.pubnub.fetchMessages({
      channels: channels,
      count: 100,
      start: timetoken,
    }, (status, results) => {
      // handle status, response

      let {messages = []} = this.state;
      if (results.channels[channels[0]] && results.channels[channels[0]].length > 0) {
        messages = messages.concat(results.channels[channels[0]]);
        messages = _.sortBy(messages, ["timetoken"]);

        this.setState({messages: messages}, () => {
          // let newHeight = document.querySelector('#messageList') && document.querySelector('#messageList').scrollHeight || 0;
          //
          // if (oldHeight && newHeight && newHeight > oldHeight) {
          //   document.querySelector('#messageList').scrollTop = newHeight - oldHeight;
          // }

        });
      }
    });
  };

  updateMessageStore = (results, isLoadMore = false) => {
    let {messagesList, messagesIds} = this.state;

    Object.keys(results.channels).forEach(channel => {
      if (typeof messagesList[`${channel}`] === 'undefined') {
        messagesList[`${channel}`] = {}
      }
      if (typeof messagesIds[`${channel}`] === 'undefined') {
        messagesIds[`${channel}`] = []
      }
      // if (typeof _this.deleted_ids[`${channel}`] === 'undefined') {
      //   _this.deleted_ids[`${channel}`] = []
      // }

      results.channels[channel].forEach(msg => {
        messagesList[`${channel}`][`${msg.message.message_id}`] = msg;
        messagesIds[`${channel}`].push(msg.message.message_id);
      });
    });

    this.setState({messagesList: messagesList, messagesIds: messagesIds}, () => {
      this.updateChat();
    });
  };

  updateChat = () => {
    const {channel} = this.props;
    const {messagesList} = this.state;
    let messages = messagesList[channel] && Object.keys(messagesList[channel]).map(id => {
      return messagesList[channel][id]
    }) || [];

    messages = _.sortBy(messages, ["timetoken"]);
    let sortedMessage = this.chatHelper.sortMessagesByDate(messages);
    this.setState({selectedChatMessages: sortedMessage});
  }

  render() {

    return <section className="py-0">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="border m-5 p-5">

              <Comments
                owner={this.chatHelper.getUUID()}
                companyId={this.props.companyId}
                companyName={this.props.pct.name}
                messages={this.state.messages}
                channels={[this.state.channel]}
                chatHelper={this.chatHelper}
                fetchMore={this.fetchMore}
                selectedChatMessages={this.state.selectedChatMessages}
              />

              <Reply
                channel={this.state.channel}
                chatHelper={this.chatHelper}
                companyId={this.props.companyId}
                user={this.props.user}
                userName={this.props.userName}
              />

            </div>
          </div>
        </div>
      </div>
    </section>
  }

}

export default CommentWidget;