import React, {Fragment} from "react";

export default class ProcessingLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const message = this.props.message || "Processing...";
    return (
      <Fragment>
        <span><span className="spinner-border spinner-border-sm mr-2" role="status"
                    aria-hidden="true"/>{" "}{message}</span>
      </Fragment>
    );
  }
}

