import AxiosMaster from './AxiosMaster';

class API {
  constructor(base) {
    this.axiosMaster = new AxiosMaster({token: localStorage.getItem('token'), base});
  }

  getDirect(url) {
    return this.axiosMaster.getDirect(url);
  };

  generateExcel(data) {
    return this.axiosMaster.postRequest(`/apis/v2`, data).then(data => {
      return data.data
    })
      .catch(err => {
        return err && err.response && err.response.data
      });
  };

};

export const APIFactory = API;
